import React from 'react'

import Layout from '../components/layout'
import { TitlePage } from '../components/common/titlePage'
import { SpecialOffer } from '../components/specialOffer'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from '../components/seo'


const BydgoszczOfertySpecjalne = () => {
    const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "oferty-specjalne.jpg"}) {
          id
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              
            )
          }
        }
      }
    `)

    return (
        <Layout bydgoszcz>
          <Seo
            title="Bydgoszcz oferty specjalne - atrakcyjne ceny"
            description="Poznaj nasze aktualne promocje. Kriolipoliza CoolTech w pakiecie taniej do -25%. Zapraszamy!" 
            url="https://bodymedgroup.pl/bydgoszcz-oferty-specjalne"
          />
            <TitlePage
                title='Oferty Specjalne' 
                text='Cieszymy się, że z Nami jesteś. Skorzystaj z wyjątkowych promocji w BodyMed Group!' 
                imageTitle={data.file.childImageSharp.gatsbyImageData}
                titleSeoImage="Programmers working in the office" 
                altSeoImage="Programmers working in the office"
            />
            <SpecialOffer bydgoszcz/>
        </Layout>
    )}

export default BydgoszczOfertySpecjalne
